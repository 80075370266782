export const disclaimer = `Keller Mortgage® Smarter Mortgage™ are trademarks, service marks, or registered service marks, trademarks, or tradenames licensed to Keller Mortgage, LLC for its use and may not be reproduced, removed or modified without written consent from Keller Mortgage, LLC.

Certain officers, directors, and owners of Keller Williams Realty, Inc. have ownership interest in Keller Mortgage. Keller Mortgage, LLC is an affiliate of Keller Williams Realty, Inc. VA Lender ID5601100000; FHA Direct-Endorsed Lender No. 241940009; 4725 Lakehurst Ct., Suite 400, Dublin, OH 43016. Equal Housing Lender.

© ${new Date().getFullYear()} Keller Mortgage LLC, All rights reserved.`;

export const NMLScode = 'NMLS #140234 Consumer Access';

export const links = {
  blog: 'https://kellermortgage.com/blog/',
  careers: 'https://jobs.lever.co/kwx/',
  licensing: 'https://kellermortgage.com/disclosures-licensing/',
  privacy: 'https://kellermortgage.com/privacy-policy/',
  legal: '/legal',
};
