import React, { ReactNode, RefObject } from 'react';
import styles from './container.module.scss';

interface ContainerProps {
  children: ReactNode;
  fullwidth?: boolean;
  horizontalSpacing?: 'small' | 'medium' | 'none';
  className?: string;
  refProp?: RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
}

const Container = ({
  children,
  fullwidth = false,
  horizontalSpacing = 'medium',
  className = '',
  refProp = { current: null },
  style,
}: ContainerProps) => {
  const GetHorizontalSpacing = () => {
    switch (horizontalSpacing) {
      case 'small':
        return styles.smallHorizontalSpacing;
      case 'medium':
        return styles.mediumHorizontalSpacing;
      case 'none':
        return styles.noHorizontalSpacing;
      default:
        return '';
    }
  };
  return (
    <div
      className={`${styles.container} ${
        fullwidth ? styles.fullwidth : ''
      } ${GetHorizontalSpacing()} ${className}`}
      ref={refProp}
      style={style}
    >
      {children}
    </div>
  );
};

export default Container;
