import Container from 'components/commons/Container/Container';
import Logo from 'components/commons/svg/Logo';
import Link from 'next/link';
import EqualHousingImg from 'src/../public/img/equal-housing.png';
import { disclaimer, links, NMLScode } from 'src/data/lang/footer.lang';
import sendGaEvent from 'src/utils/gtag';
import styles from './footer.module.scss';

const Footer = () => (
  <footer className={styles.lpFooter}>
    <Container fullwidth horizontalSpacing="none">
      <div className={styles.welcomeMat} />
    </Container>
    <>
      <Container
        fullwidth
        horizontalSpacing="none"
        className={styles.landingPage}
      >
        <Container className={styles.footerCopy}>
          <>
            <div className={styles.lhs}>
              <div>
                <Logo
                  fillMain="#FFFFFF"
                  fillAccent="#FFFFFF"
                  height="60px"
                  width="254px"
                />
                <div className={styles.footerLinkContainer}>
                  <a
                    className={styles.footerLink}
                    href={links.careers}
                    onClick={() => sendGaEvent('clickedCareers', 'shared')}
                  >
                    Careers
                  </a>
                  <Link href={links.legal}>
                    <a
                      href="/"
                      onClick={() => sendGaEvent('clickedLegal', 'shared')}
                      className={styles.footerLink}
                    >
                      Legal
                    </a>
                  </Link>
                </div>
              </div>
              <a
                href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/140234 "
                className={styles.equalLanguage}
                onClick={() => sendGaEvent('clickedLender', 'shared')}
              >
                <span>
                  <div className={styles.equalHousingImage}>
                    <img
                      src={EqualHousingImg}
                      alt="equal housing logo"
                      width="35"
                      height="40"
                    />
                  </div>
                </span>
                <span className={styles.nmlsCode}>{NMLScode}</span>
              </a>
            </div>
            <p>{disclaimer}</p>
          </>
        </Container>
      </Container>
    </>
  </footer>
);

export default Footer;
