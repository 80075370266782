import React from 'react';
import sendGaEvent from 'src/utils/gtag';
import PrimaryButton from 'components/commons/PrimaryButton/PrimaryButton';
import { useFormMachine } from 'src/context/FormMachineProvider';
import { GaPage, GaEvent } from 'src/common/types';

interface ApplyNowButtonProps {
  children: string;
  gaEvent: GaEvent;
  gaPage: GaPage;
  look?: 'applyNow';
  specialWidth?: 'small';
  testID?: string;
  customClass?: string;
}

const ApplyNowButton = ({
  children,
  gaEvent,
  gaPage,
  look,
  specialWidth,
  customClass,
  testID = '',
}: ApplyNowButtonProps) => {
  const [, send] = useFormMachine();

  const handleBeginFlow = () => {
    sendGaEvent(gaEvent, gaPage);
    send('START_FLOW');
  };

  return (
    <PrimaryButton
      handleOnClick={handleBeginFlow}
      look={look}
      specialWidth={specialWidth}
      testID={testID}
      customClass={customClass}
      cypressTag="apply"
    >
      {children}
    </PrimaryButton>
  );
};

export default ApplyNowButton;
